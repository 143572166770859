<script>
    /* eslint-disable */
    import fullscreen from 'vue-fullscreen';
    import Vue from 'vue';
    import {isMobileOnly} from 'mobile-device-detect';
    import {mapGetters} from 'vuex';
    import {vAvatar} from '@amaury-tobias/v-avatar';

    Vue.use(fullscreen);

    export default {
        name: 'site-header',
        components: {
            vAvatar
        },
        data() {
            return {
                fullscreen: false,
                resizer: null,
                showCompactSideBarForMobileVertical: false,
            };
        },
        methods: {
            handleResize() {
                if (this.resizer) {
                    clearTimeout(this.resizer);
                }
                let param = 425;
                this.resizer = setTimeout(() => {
                    let body = document.querySelector('body');
                    this.showCompactSideBarForMobileVertical = isMobileOnly && ((body.clientWidth > param));
                    if (this.showCompactSideBarForMobileVertical) {
                        body.classList.add('compact-sidebar');
                    } else if (!isMobileOnly && body.clientWidth <= 768) {
                        body.classList.add('compact-sidebar');
                    } else {
                        body.classList.remove('compact-sidebar');
                    }
                }, 100);
            },
            toggleSideBarFirst: function () {
                let body = document.querySelector('body');
                body.classList.toggle('site-sidebar-opened');
                return false;
            },
            toggleSideBarSecond: function () {
                let body = document.querySelector('body');
                body.classList.toggle('compact-sidebar');
                return false;
            },
            logout: function () {
                this.$store.dispatch('auth/logout')
                    .then(() => {
                        this.$router.push('/'); // TODO Check the role
                    });
            },
            toggleFullscreen() {
                let body = document.querySelector('body');
                this.$fullscreen.toggle(body, {
                    wrap: false,
                    callback: this.fullscreenChange
                });
            },
            fullscreenChange(fullscreen) {
                this.fullscreen = fullscreen;
            }
        },
        computed: {
            ...mapGetters({
                currentUserAvatar: 'currentUser/getCurrentUserAvatar',
                isUserRoleAssessor: 'currentUser/isRoleAssessor',
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
            }),

            count: function () {
                return this.$store.state.count;
            },
            userInfo: function () {
                return this.$store.state.currentUser;
            },
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
            this.$nextTick(function () {
                this.handleResize();
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
    };
</script>

<template>
    <div>

        <!-- Preloader -->
        <div class="preloader" style="display: none"></div>
        <!-- Header -->
        <div class="site-header">
            <b-navbar class="mobile-header mobile-show">
                <b-navbar-nav class="center-middle">
                    <a @click="'/'" class="navbar-brand-logo-svg-header"></a>
                    <div @click="toggleSideBarFirst" class="toggle-button light sidebar-toggle-first float-xs-left hidden-md-up">
                        <span class="hamburger"></span>
                    </div>
                </b-navbar-nav>
                <ul class="navbar-nav ml-auto">
                    <b-nav-item-dropdown right>
                        <template v-slot:button-content>
                                <span class="avatar">
                                    <v-avatar
                                            :username="userInfo.fullName"
                                            :src="currentUserAvatar"
                                            :size="40"
                                            rounded
                                            background-color="var(--supplier-color)"
                                    />
                                </span>
                        </template>

                        <router-link :to="{name: 'Settings', hash: '#settings-account'}" class="dropdown-item">
                            <i class="ti-user mr-0-5"></i> My Account
                        </router-link>
                        <router-link v-if="!isUserRoleAssessor && !isUserRoleLawyer" :to="{name: 'Settings', hash: '#settings-business-profile'}" class="dropdown-item">
                            <i class="ti-briefcase mr-0-5"></i> Business
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item  clickable" @click.prevent="logout"><i class="ti-power-off mr-0-5"></i> Sign out</a>
                    </b-nav-item-dropdown>

                </ul>
            </b-navbar>
            <b-navbar class="mobile-hide">
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <div @click="toggleSideBarSecond" class="nav-link toggle-button light sidebar-toggle-first float-xs-left hidden-md-up">
                            <span class="hamburger"></span>
                        </div>
                        <div @click="toggleFullscreen" class="nav-link toggle-fullscreen">
                            <i class='ti-fullscreen'></i>
                        </div>
                    </b-navbar-nav>

                    <!-- Right aligned nav items -->
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item d-block">
                            <span class="full-name">{{userInfo.firstName}} {{userInfo.lastName}}</span>
                        </li>
                        <b-nav-item-dropdown right>
                            <template v-slot:button-content>
                                <span class="avatar">
                                    <v-avatar
                                            :username="userInfo.fullName"
                                            :src="currentUserAvatar"
                                            :size="40"
                                            color="#fff"
                                            rounded
                                            background-color="var(--supplier-color)"
                                    />
                                </span>
                            </template>

                            <router-link :to="{name: 'Settings', hash: '#settings-account'}" class="dropdown-item">
                                <i class="ti-user mr-0-5"></i> My Account
                            </router-link>
                            <router-link v-if="!isUserRoleAssessor && !isUserRoleLawyer" :to="{name: 'Settings', hash: '#settings-business-profile'}" class="dropdown-item">
                                <i class="ti-briefcase mr-0-5"></i> Business
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <a href="#" class="dropdown-item  clickable" @click.prevent="logout"><i class="ti-power-off mr-0-5"></i> Sign out</a>
                        </b-nav-item-dropdown>
                    </ul>
                </b-collapse>
            </b-navbar>
            <nav class="navbar navbar-light" style="display:none;">
                <div class="navbar-left center-middle">
                    <a @click="'/'" class="navbar-brand-logo-svg-header"></a>
                    <div @click="toggleSideBarFirst" class="toggle-button light sidebar-toggle-first float-xs-left hidden-md-up">
                        <span class="hamburger"></span>
                    </div>
                </div>
                <div class="navbar-right navbar-toggleable-sm collapse show" id="collapse-1">
                    <div @click="toggleSideBarSecond" class="toggle-button light sidebar-toggle-second hidden-sm-down">
                        <span class="hamburger"></span>
                    </div>
                    <ul class="nav navbar-nav1 float-md-right">
                        <li class="nav-item hidden-sm-down">
                            <span class="nav-link active">{{userInfo.firstName}} {{userInfo.lastName}}</span>
                        </li>
                        <li class="nav-item dropdown hidden-sm-down">
                            <a href="#" data-toggle="dropdown" aria-expanded="false">
                                <span class="avatar">
                                    <v-avatar
                                            :username="userInfo.fullName"
                                            :src="currentUserAvatar"
                                            :size="40"
                                            color="#fff"
                                            rounded
                                            background-color="var(--supplier-color)"
                                    />
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                                <router-link :to="{name: 'Settings', hash: '#settings-account'}" class="dropdown-item">
                                    <i class="ti-user mr-0-5"></i> My Account
                                </router-link>
                                <router-link v-if="!isUserRoleAssessor && !isUserRoleLawyer"  :to="{name: 'Settings', hash: '#settings-business-profile'}" class="dropdown-item">
                                    <i class="ti-briefcase mr-0-5"></i> Business
                                </router-link>

                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#"><i class="ti-help mr-0-5"></i> Help</a>
                                <a href="#" class="dropdown-item clickable" @click.prevent="logout"><i class="ti-power-off mr-0-5"></i> Sign out</a>
                            </div>
                        </li>
                    </ul>
                    <ul class="nav navbar-nav">
                        <li class="nav-item hidden-sm-down">
                            <a class="nav-link toggle-fullscreen" href="#">
                                <i class="ti-fullscreen"></i>
                            </a>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<style>
    .navbar-light .navbar-brand {
        color: #212330 !important;
    }

    .center-middle {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 767px) {
        .navbar-left.center-middle .toggle-button.light.sidebar-toggle-first.float-xs-left.hidden-md-up {
            position: absolute;
            left: 0;
        }
    }

    .V3 .dropdown-toggle::after {
        display: none;
    }
</style>

<style>
    /* =================================================================
  Header
================================================================= */

    .V3 .fullscreen .wrapper {
        background-color: #FFFFFF;
    }

    .V3 .fixed-header .site-header {
        position: fixed;
        top: 0;
        left: 240px;
        right: 0;
        z-index: 10010;
    }

    @media (max-width: 767px) {
        .V3 .fixed-header .site-header {
            left: 0;
        }

        .V3 .fixed-header.compact-sidebar .site-header {
            left: 0 !important;
        }

        .V3 .fixed-header .site-header .navbar-brand-logo-svg-header {
            background-image: url('~@/assets/logos/logo.svg');
            background-size: 100% auto;
            display: block;
            width: 120px;
            height: 24.31px;
            background-repeat: no-repeat;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }

        .V3 .V3.fixed-header.site-sidebar-opened .site-header .navbar-brand-logo-svg-header {
            display: none;
        }
    }

    .V3 .fixed-header.compact-sidebar .site-header {
        left: 70px;
    }

    .V3 .site-header .navbar {
        padding: 0;
        background-color: #FFFFFF;
    }

    .V3 .site-header .navbar-left {
        background-color: #212330;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    @media (min-width: 768px) {
        .V3 .site-header .navbar-left {
            float: left;
            width: 240px;
            display: none;
        }

        .V3 .compact-sidebar .site-header .navbar-left {
            width: 70px;
            display: none;
        }
    }

    .V3 .site-header .navbar-brand {
        display: inline-block;
        float: none;
        margin: 0;
        padding: 1.3rem 0 0;
        height: 60px;
    }

    .V3 .site-header .navbar-brand .logo {
        width: 100px;
        height: 25px;
    }

    @media (min-width: 768px) {
        .V3 .compact-sidebar .site-header .navbar-brand .logo {
            width: 25px;
        }
    }

    .V3 .site-header .navbar-right {
        padding: 0 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 768px) {
        .V3 .site-header .navbar-right {
            margin-left: 0px;
            height: 60px !important;
        }

        .V3 .compact-sidebar .site-header .navbar-right {
            margin-left: 0px;
        }
    }

    .V3 .site-header .toggle-button {
        position: relative;
        width: 55px;
        height: 60px;
        line-height: 60px;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .V3 .site-header .mobile-header .toggle-button {
        position: absolute;
        left: 0;
    }

    @media (max-width: 425px) {
        .V3 .site-header .mobile-hide {
            display: none;
        }

    }

    .V3 .site-header .navbar-right .toggle-button {
        margin-left: -15px;
    }

    .V3 .site-header .toggle-button.light {
        color: rgba(0, 0, 0, 0.5);
    }

    .V3 .site-header .toggle-button.dark {
        color: rgba(255, 255, 255, 0.5);
    }

    .V3 .site-header .toggle-button span {
        position: absolute;
        display: block;
        top: 50%;
        right: 0;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-header span.hamburger {
        left: 15px;
        margin-top: -1px;
        width: 23px;
        height: 3px;
        border-radius: 2px;
    }

    .V3 .site-header .toggle-button span.more {
        left: 50%;
        margin: -2px 0 0 -2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
    }

    .V3 .site-header .toggle-button.light span {
        background-color: #1B1E38;
    }

    .V3 .site-header .toggle-button.dark span {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .V3 .site-header .toggle-button span:before,
    .V3 .site-header .toggle-button span:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-header span.hamburger:before,
    .V3 .site-header span.hamburger:after {
        height: 3px;
        border-radius: 2px;
    }

    .V3 .site-header .toggle-button span.more:before,
    .V3 .site-header .toggle-button span.more:after {
        height: 4px;
        border-radius: 50%;
    }

    .V3 .site-header .toggle-button.light span:before,
    .V3 .site-header .toggle-button.light span:after {
        background-color: #1B1E38;
    }

    .V3 .site-header .toggle-button.dark span:before,
    .V3 .site-header .toggle-button.dark span:after {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .V3 .site-header span.hamburger:before {
        top: -6px;
        width: 50%;
    }

    .V3 .site-header span.hamburger:after {
        bottom: -6px;
        width: 50%;
        margin-left: 50%;
    }

    .V3 .site-header .toggle-button span.more:before {
        top: -8px;
    }

    .V3 .site-header .toggle-button span.more:after {
        bottom: -8px;
    }

    .V3 .site-header .toggle-button.light:hover span,
    .V3 .site-header .toggle-button.light:hover span:before,
    .V3 .site-header .toggle-button.light:hover span:after,
    .V3 .site-header .toggle-button.light:focus span,
    .V3 .site-header .toggle-button.light:focus span:before,
    .V3 .site-header .toggle-button.light:focus span:after {
        background-color: #333333;
    }

    .V3 .site-header .toggle-button.dark:hover span,
    .V3 .site-header .toggle-button.dark:hover span:before,
    .V3 .site-header .toggle-button.dark:hover span:after,
    .V3 .site-header .toggle-button.dark:focus span,
    .V3 .site-header .toggle-button.dark:focus span:before,
    .V3 .site-header .toggle-button.dark:focus span:after {
        background-color: #FFFFFF;
    }

    .V3 .site-header .toggle-button-second {
        width: 45px;
        height: 59px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
    }

    .V3 .site-header .navbar-right .toggle-button-second {
        margin: 0 -15px 0 15px;
    }

    .V3 .site-header .toggle-button-second.light {
        border-left: 1px solid rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.5);
    }

    .V3 .site-header .toggle-button-second.dark {
        border-left: 1px solid rgba(255, 255, 255, 0.125);
        color: rgba(255, 255, 255, 0.7);
    }

    .V3 .site-header .toggle-button-second i {
        display: inline-block;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-header .toggle-button-second.active i {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .V3 .site-header .navbar-nav > li > a,
    .V3 .site-header .navbar-nav > li > span {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 768px) {
        .V3 .site-header .navbar-nav > li > a,
        .V3 .site-header .navbar-nav > li > span {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 60px;
        }
    }

    .V3 .site-header .navbar-nav > li > a i {
        position: relative;
        top: 2px;
        font-size: 1.3rem;
    }

    .V3 .site-header .navbar-nav > li .btn {
        vertical-align: baseline;
    }

    .V3 .site-header .navbar-nav > li .avatar {
        vertical-align: middle;
    }

    .V3 .site-header .navbar-nav > li .avatar span {
        font: 16px / 40px Nunito Sans !important;
    }

    .V3 .site-header .navbar .dropdown-menu {
        margin-top: -1px;
    }

    @media (max-width: 767px) {
        .V3 .site-header .navbar .navbar-toggleable-sm {
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            padding: 1rem 2rem;
            background-color: #FFFFFF;
            border-bottom: 1px solid #DDDDDD;
        }
    }

    @media (max-width: 991px) {
        .V3 .site-header .navbar {
            margin-left: 0 !important;
        }
    }

    .V3 .site-header .header-form {
        padding-top: 1.1rem;
    }

    @media (max-width: 767px) {
        .V3 .site-header .header-form {
            padding: 0.75rem 0;
        }
    }

    .V3 .site-header .header-form form {
        position: relative;
    }

    .V3 .site-header .header-form .btn {
        position: absolute;
        top: 1px;
        right: 1px;
        color: #999999;
    }

    @media (min-width: 768px) {
        .V3 .site-header .header-form .form-control {
            width: 180px;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }

        .V3 .site-header .header-form .form-control:focus {
            width: 300px;
        }
    }

    .V3 .site-header .dropdown-more {
        display: block;
        padding: 0.75rem 1rem;
        background-color: #EEEEEE;
        color: #999999;
        text-align: center;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-header .dropdown-more:hover,
    .V3 .site-header .dropdown-more:focus {
        color: #333333;
    }

    .V3 .site-header .full-name {
        color: #1B1E38;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
    }

    .site-header .toggle-fullscreen {
        padding-top: 19px;
    }

    .site-header .toggle-fullscreen i.ti-fullscreen {
        font-size: 20px;
        color: #1B1E38;
        opacity: 0.25;
        cursor: pointer;
    }

    .site-header .toggle-fullscreen i.ti-fullscreen:hover {
        opacity: 1;
    }

    @media (max-width: 767px) {
        .V3 .mobile-hide {
            display: none !important;
        }

        .V3 .mobile-header.mobile-show {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
    }

    @media (min-width: 768px) {
        .V3 .mobile-hide {
            /*display:none !important;*/
        }

        .V3 .mobile-header.mobile-show {
            display: none !important;
        }
    }

    .V3 .site-header .navbar .nav-item .full-name {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 60px;
    }

</style>
