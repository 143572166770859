/*eslint-disable */

export function getFilter(state) {
    return state.filter;
}

export function getTableSortBy(state) {
    return state.tableSortBy;
}

export function getTableSortDesc(state) {
    return state.tableSortDesc;
}
