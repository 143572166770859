/*eslint no-unused-vars: "off"*/

export const Actions = {
    initAppAfterLogin(context) {
        return new Promise((resolve, reject) => {
            context.dispatch('currentUser/loadUserInfo', {}, {root: true}).then(() => {
                context.dispatch('dashboard/loadReports', {}, {root: true});
                context.dispatch('currentCompany/loadCompanyInfo', {}, {root: true});
                context.dispatch("claim/init", {}, {root: true});
                context.dispatch("assessment/init", {}, {root: true});
                context.dispatch("invoice/init", {}, {root: true});
                context.dispatch("legalfirm/init", {}, {root: true});
          //      context.dispatch("assessor/init", {}, {root: true});
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    },
};
