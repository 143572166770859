/*eslint-disable */

export function getAllAssessors(state) {
    return state.items;
}

export function getFilter(state) {
    return state.filter;
}

export function getCurrentAssessor(state) {
    return state.currentAssessor;
}

export function countAllAssessors(state) {
    let ids = state.items;
    return ids.length;
}
