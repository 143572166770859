/*eslint-disable */
import Axios from "axios";
import _ from "lodash";
import {CLAIMS_FILTER_UPDATE, CLAIMS_TABLE_SORT_BY_UPDATE, CLAIMS_TABLE_SORT_DESC_UPDATE, CLAIMS_UPDATED, CLAIMS_REMOVE} from '../../mutation-types';

export function getClaimById(context, rfqId) {
    return new Promise((resolve, reject) => {
        Axios.get('/ir/claim/' + rfqId)
            .then((response) => {
                context.commit(REPAIRER_CURRENT_RFQ_UPDATE, response.data.rfq);
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function setFilter(context, filter) {
    context.commit(CLAIMS_FILTER_UPDATE, filter);
}

export function setTableSortBy(context, filter) {
    context.commit(CLAIMS_TABLE_SORT_BY_UPDATE, filter);
}

export function setTableSortDesc(context, filter) {
    context.commit(CLAIMS_TABLE_SORT_DESC_UPDATE, filter);
}

export function getClaimsByIds(context, payload) {
    Axios.post('/ir/repairer/rfqs', {ids: payload})
        .then(response => {
            context.commit(REPAIRER_RFQ_UPDATED, response.data.rfqs);
        })
        .catch(error => {
            console.log(error);
        });
}

export function getClaimsForTableView({dispatch, rootGetters, commit }, payload = {page: 1, timestamp: 0}) {

    let page = payload.page;
    let url = '/ir/claims/page/';
    if (!_.isEmpty(payload.timestamp)) url = '/ir/claims/' + payload.timestamp + '/page/';

    return Axios.post(url + page)
        .then(response => {
            commit(CLAIMS_UPDATED, response.data.claims);
            if (response.data._timestamp && response.data._timestamp > 0 && rootGetters['updater/lastTimestamp'] == 0) {
                dispatch('updater/setLastTimestamp', response.data._timestamp, {root: true});
            }
            if (response.data._page) {
                page = response.data._page;
                return dispatch('getClaimsForTableView', {
                    page: response.data._page,
                    timestamp: payload.timestamp
                });
            }
        })
        .catch(error => {
            console.log(error);
        });

}

export function init(context, payload = 0) {
    return getClaimsForTableView(context, payload);
}

export function deleteClaimById(context, id) {
    context.commit(CLAIMS_REMOVE, id);
}