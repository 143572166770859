<script>
import NProgress from 'nprogress';
import Axios from "axios";

export default {
    data() {
        return {
            username: "",
            password: "",
            isCheckUsername: true,
            isCheckPassword: true,
        };
    },
    methods: {
        goToHome() {
            this.$router.push('/');
        },
        onClickForgotUsername() {
            this.$router.push({name: 'ForgotUserNamePage'});
        },
        onClickForgotPassword() {
            this.$router.push({name: 'ForgotPasswordPage'});
        },
        checkUsername: function () {
            let username = this.username;
            this.isCheckPassword = true;
            Axios({url: '/ir/auth/check/username', data: {username: username}, method: 'POST'})
                .then(resp => {
                    if (resp && resp.data && resp.data.status == false) {
                        this.isCheckUsername = false;
                    } else {
                        this.isCheckUsername = true;
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        login: function () {
            let self = this;
            let username = this.username;
            let password = this.password;
            NProgress.start();
            this.$store
                .dispatch("auth/login", {username, password})
                .then(() => {
                    this.$store.dispatch("auth/successLogin").then(() => {
                        let currentPath = this.$router.currentRoute.path;
                        if (currentPath === '/login') {
                            this.$router.push('/dashboard');

                        }
                    });
                    NProgress.done();
                    //if (this.$store.getters["currentUser/isUserTypeSupplier"]) {
                    //  this.$router.push("/s");
                    //}else{
                    //  this.$router.push("/home33");
                    //}
                })
                .catch(() => {
                    NProgress.done();
                    self.isCheckPassword = false;
                })
        }
    },
    mounted() {
        console.log("this.$router.currentRoute", this.$router.currentRoute.path);
    },
    computed: {
        errorUsername() {
            if (!this.isCheckUsername) {
                return true;
            }
            return false;
        },
        errorPassword() {
            if (!this.isCheckPassword) {
                return true;
            }
            return false;
        },
        isLoginPath() {
            return this.$route.path === '/login' || this.$route.path === '/auth'
        }
    }
};
</script>

<template>
    <div class="container-fluid login-page page">
        <div class="content">

            <div class="form-container column">
                <div class="form">
                    <div class="auth-header">
                        <div class="logo">
                            <h1 class="logo-text">ClaimLinq</h1>
                        </div>
                        <span class="close" @click.prevent="goToHome">×</span>
                    </div>
                    <form method="POST" @submit.prevent="login" v-if="isLoginPath">
                        <h2 class="header-text">Welcome Back</h2>
                        <br/>
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input type="text"
                                   id="username"
                                   class="form-control input-field"
                                   placeholder="Enter your username"
                                   name="username"
                                   v-model="username"
                                   @change="checkUsername()"
                            />
                            <div class="w-100 text-right">
                                <span v-if="errorUsername" class="error-text">
                                  <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;We could not find that Username. Try again
                                </span>
                                <a href="" @click.prevent="onClickForgotUsername">Forgot your Username?</a>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input id="password"
                                   type="password"
                                   class="form-control input-field"
                                   placeholder="Enter your password"
                                   v-model="password"
                                   name="password"
                            />
                            <div class="container-fluid w-100">
                                <span v-if="errorPassword" class="error-text mb-sm-1">
                                  <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;The password entered is incorrect. Try again
                                </span>
                                <div class="w-100 text-right row justify-content-between">
                                    <label class="input-checkbox mr-1">
                                        <input name="remember"
                                               checked
                                               type="checkbox"
                                               aria-label="Remember this device"
                                        />
                                    </label>&nbsp;
                                    <a href="" class="text-right recover-password"
                                       @click.prevent="onClickForgotPassword">Forgot your Password?</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn input-btn">Log in</button>
                            <br/>
                            <br/>
                        </div>
                    </form>
                    <router-view />

                </div>
            </div>
        </div>
    </div>
</template>

<style>
@import "../../styles/login/style.css";

.login-page {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 111121;
}

.theme-claimlinq .login-page .input-btn {
    background-color: #00b050;
    border-color: #00b050;
    color: white;
}

.theme-claimlinq .login-page .input-btn:hover {
    color: white;
    cursor: pointer;
}

.theme-claimlinq .login-page .form a {
    color: #00b050;
}

@media screen and (max-width: 575.98px) {
    .theme-claimlinq .login-page.page .content {
        width: 100%;
        padding: 0 15px;
    }
}

</style>
