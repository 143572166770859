/*eslint-disable */
import _ from 'lodash';

export function getAllClaims(state) {
    return state.items;
}

export function getNewClaims(state) {
    let claims = _.filter(state.items, function (i) {
        return i.status == "New";
    });
    return claims;
}

export function getOnHoldClaims(state) {
    let claims = _.filter(state.items, function (i) {
        return i.status == "OnHold";
    });
    return claims;
}

export function getSentToRecoveriesClaims(state) {
    let claims = _.filter(state.items, function (i) {
        return i.status == "SentToRecoveries";
    });
    return claims;
}

export function getCompletedClaims(state) {
    let claims = _.filter(state.items, function (i) {
        return i.status == "Completed";
    });
    return claims;
}

export function getCurrentClaim(state) {
    return state.currentClaim;
}

export function countAllClaims(state) {
    let ids = state.items;
    return ids.length;
}

export function getFilter(state) {
    return state.filter;
}

export function getTableSortBy(state) {
    return state.tableSortBy;
}

export function getTableSortDesc(state) {
    return state.tableSortDesc;
}

export function countNewClaims(state) {
    let claims = _.filter(state.items, function (i) {
        return i.systemStatus == "New Claim";
    });
    return claims.length;
}

export function countApprovedClaims(state, getters) {
    let claims = _.filter(state.items, function (i) {
        return i.systemStatus == "Approved";
    });
    return claims.length;
}
export function countWRecoveriesClaims(state, getters) {
    let claims = _.filter(state.items, function (i) {
        return i.systemStatus == "With Recoveries";
    });
    return claims.length;
}
export function countDeniedClaims(state, getters) {
    let claims = _.filter(state.items, function (i) {
        return i.systemStatus == "Claim Denied";
    });
    return claims.length;
}

export  function getClaimsPerPage(state) {
    return state.claimsPerPage;
}

export  function getClaimsCurrentPage(state) {
    return state.claimsCurrentPage;
}