<template>
    <div :class="className" v-show="isActive">
    <section
            :aria-hidden="!isActive"
            class="tab-pane tabs-component-panel"
            :class="{'active': isActive}"
            :id="hash"
            role="tabpanel"
    >
        <slot />
    </section>
    </div>
</template>

<script>
    export default {
        props: {
            id: { default: null },
            name: { required: true },
            prefix: { default: '' },
            suffix: { default: '' },
            isDisabled:{ default: false },
            className: {default: 'tab-content'},
            counter: {default: null},
            propsActive: {
                type: Boolean,
                default: false,
                required: false,
            },
            isShow: {
                type: Boolean,
                default: true,
                required: false,
            },
            order: {
                type: Number,
                default: 0,
                required: false,
            }
        },

        data: () => ({
            isActive: false,
            isVisible: true,
            isTab: true,
        }),

        computed: {
            header() {
                let header = this.prefix + this.name + this.suffix;
                if(this.counter != null){
                  header += '<span class="tag tag-info">' + this.counter + '</span>'
                }
                return header;
            },

            computedId() {
                return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-');
            },

            hash() {
                if (this.isDisabled) {
                    return '#';
                }

                return '#' + this.computedId;
            },
        },
        mounted(){
             if (this.propsActive === true){
                 this.isActive = true;
             }
        },
    };
</script>
