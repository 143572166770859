/*eslint no-unused-vars: "off"*/
export const Getters = {

    addressStates: state => {
        return state.addressStates;
    },
    addressCountries: state => {
        return state.addressCountries;
    },
    vehicleTransmissions: state => {
        return state.vehicleTransmissions;
    },
};