/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    items: [],
    filter: '',
    invoicesPerPage: 50,
    invoicesAllCurrentPage: 1,
    invoicesAwaitingPaymentCurrentPage: 1,
    invoicesPaidCurrentPage: 1,
    sortByForInvoicesAll: 'number',
    sortDescForInvoicesAll: true,
    sortByForInvoicesAwaitingPayment: 'number',
    sortDescForInvoicesAwaitingPayment: true,
    sortByForInvoicesPaid: 'number',
    sortDescForInvoicesPaid: true,
    lastTimeCheck: {
        items: 0,
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
/*
{
    "id": "20",
    "number": "00008",
    "claimNumber": "",
    "assessmentId": "48",
    "assessmentNumber": "A00008",
    "vehicleOwner": "Test Owner",
    "createdOn": "2021-05-26 10:32:01",
    "dateInvoice": "2021-08-15 00:00:00",
    "dueDate": "2021-08-18 00:00:00",
    "subtotal": "500.00",
    "gst": "50.00",
    "total": "550.00",
    "paid": "550.00",
    "due": 0,
    "status": "paid",
    "description": "Assessment for\nName: Test Owner\nReference Nbr: C000008\nAssessment Nbr: A00008\nRego Nbr: REGO00001\nMake: AUDI\nModel: A3"
}
 */
