/*eslint-disable */
import _ from 'lodash';
import {REPAIRERS_FILTER_UPDATE, REPAIRERS_TABLE_SORT_BY_UPDATE, REPAIRERS_TABLE_SORT_DESC_UPDATE} from '../../mutation-types';

export default {
    [REPAIRERS_FILTER_UPDATE](state, ui) {
        state.filter = ui;
    },
    [REPAIRERS_TABLE_SORT_BY_UPDATE](state, ui) {
        state.tableSortBy = ui;
    },
    [REPAIRERS_TABLE_SORT_DESC_UPDATE](state, ui) {
        state.tableSortDesc = ui;
    },
};
