/*eslint no-unused-vars: "off"*/
export const Mutations = {
  loadUsers(state, ui) {
    state.users = ui;
  },
  loadDashboardSummary(state, ui) {
    state.dashboardSummary = ui;
  },

}
