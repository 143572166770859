/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

import {States} from './states';
import {Getters} from './getters';
import {Mutations} from './mutations';
import {Actions} from './actions';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: States,
  getters: Getters,
  actions: Actions,
  mutations: Mutations,
  modules,
  strict: process.env.NODE_ENV !== 'production'
})

console.log('modules',modules);

// Automatically run the `init` action for every module,
// if one exists.
for (const moduleName of Object.keys(modules)) {
  //if (modules[moduleName].actions.init) {
  //  store.dispatch(`${moduleName}/init`)
  //}
}

export default store
