/*eslint-disable */

export function getCurrentUserInfo(state) {
    return {
        id: state.id,
        username: state.username,
        firstName: state.firstName,
        lastName: state.lastName,
        fullName: state.fullName,
        isActive: state.isActive,
        isVisible: state.isVisible,
        workPhone: state.workPhone,
        mobilePhone: state.mobilePhone,
        businessLocation: state.businessLocation,
        userPhoto: state.userPhoto,
        email: state.email,
        activeSessions: state.activeSessions,
        lastLoggedIn: state.lastLoggedIn,
        role: state.role,
    };
}

export function getCurrentUserFullName(state) {
    return state.firstName + ' ' + state.lastName;
}

export function getCurrentUserId(state) {
    return state.id;
}

export function getCurrentUserAvatar(state) {
    return state.userPhoto;
}

export function isRoleAssessor(state) {
    return !!(state.role === "Assessor");
}

export function isRoleAdministrator(state) {
    return !!(state.role === "Administrator");
}

export function isRoleManager(state) {
    return !!(state.role === "Manager");
}

export function isRoleLawyer(state) {
    return !!(state.role === "Lawyer");
}
