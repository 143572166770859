const Demo = () => import(/* webpackChunkName: "demo" */  '@/demo');
const DemoPayMyDeposit = () => import(/* webpackChunkName: "demo" */  '@/demo/payMyDeposit');
const DemoRequestLicense = () => import(/* webpackChunkName: "demo" */  '@/demo/requestLicense');
const DemoClaimForm = () => import(/* webpackChunkName: "demo" */  '@/demo/claimForm');

const demoRoutes = [
    {
        path: '/demo',
        name: 'Demo',
        component: Demo,
    },
    {
        path: '/demo/32-pay-my-deposit',
        name: 'YRTR Pay My Deposit',
        component: DemoPayMyDeposit,
    },
    {
        path: '/demo/31-request-license',
        name: 'YRTR Request License',
        component: DemoRequestLicense,
    },
    {
        path: '/demo/30-claim-form',
        name: 'YRTR Claim Form',
        component: DemoClaimForm,
    }
]
export default demoRoutes;