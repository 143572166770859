//AUTH
export const AUTH = 'auth';
export const AUTH_LOGIN_REQUEST = 'authLoginRequest';
export const AUTH_LOGIN_SUCCESS = 'authLoginSuccess';
export const AUTH_LOGIN_FAILD = 'authLoginFaild';
export const AUTH_TOKEN_EXPIRE = 'authTokenExpire';
export const AUTH_LOGOUT_REQUEST = 'authLogoutRequest';
export const AUTH_LOGOUT_SUCCESS = 'authLogoutSuccess';
export const AUTH_LOGOUT_FAILD = 'authLogoutFaild';
export const AUTH_GOT_401 = 'authGot401';

export const DASHBOARD_SET_SHOW_COUNT_TASKS = 'dashboardSetShowCountTasks';
export const DASHBOARD_SET_SHOW_COUNT_EMAILS = 'dashboardSetShowCountEmails';
export const DASHBOARD_SET_SHOW_OLD_TASKS = 'dashboardSetShowOldTasks';
export const DASHBOARD_SET_SHOW_OLD_COMMENTS = 'dashboardSetShowOldComments';
export const DASHBOARD_SET_SHOW_OLD_EMAILS = 'dashboardSetShowOldEmails';

export const CURRENTUSER_LOAD_INFO = 'currentUserLoadInfo';
export const CURRENTUSER_UPDATE = 'currentUserUpdate';
export const CURRENTUSER_UPDATE_USER_AVATAR = 'currentUserUpdateUserAvatar';
export const CURRENTUSER_LOGOUT = 'currentUserLogout';

export const PART_LIST_LOAD_INFO = 'partListLoadInfo';
export const BRAND_LIST_LOAD = 'brandListLoad';

export const CLAIMS_UPDATED = 'claimsUpdated';
export const CLAIMS_FILTER_UPDATE = 'claimsFilterUpdated';
export const CLAIMS_TABLE_SORT_BY_UPDATE = 'claimsTableSortByUpdated';
export const CLAIMS_TABLE_SORT_DESC_UPDATE = 'claimsTableSortDescUpdated';
export const CLAIMS_REMOVE = 'claimsRemove';

export const ASSESSMENTS_UPDATED = 'assessmentsUpdated';
export const ASSESSMENTS_FILTER_UPDATE = 'assessmentsFilterUpdated';
export const ASSESSMENTS_TABLE_SORT_BY_UPDATE = 'assessmentsTableSortByUpdated';
export const ASSESSMENTS_TABLE_SORT_DESC_UPDATE = 'assessmentsTableSortDescUpdated';
export const ASSESSMENTS_REMOVE = 'assessmentsRemove';
export const ASSESSMENTS_REMOVE_BY_CLAIM = 'assessmentsRemoveByClaim';

export const INVOICES_UPDATED = 'invoicesUpdated';
export const INVOICES_FILTER_UPDATE = 'invoicesFilterUpdated';

export const ASSESSORS_UPDATED = 'assessorsUpdated';
export const ASSESSORS_FILTER_UPDATE = 'assessorsFilterUpdated';

export const TOW_DRIVERS_FILTER_UPDATE = 'towDriversFilterUpdated';

export const LEGAL_FIRMS_UPDATED = 'legalFirmsUpdated';

export const CURRENT_CLAIM_UPDATE = 'currentClaimUpdate';
export const CURRENT_ASSESSMENT_UPDATE = 'currentAssessmentUpdate';
export const CURRENT_ASSESSOR_UPDATE = 'currentAssessorUpdate';

export const LOAD_CURRENT_USER = 'loadCurrentUser';

export const LOAD_REPORTS = 'loadReports';

export const SETTINGS_FETCH_ALL_USERS = 'settingsFetchAllUsers';
export const SETTINGS_SET_SELECTED_USER = 'settingsSetSelectedUser';
export const SETTINGS_APPEND_NEW_USER = 'settingsAppendNewUser';
export const SETTINGS_CLEAR_USERS = 'settingsClearUsers';

export const ADD_ITEM_PART_FOR_SAVE = 'addItemPartForSave';
export const PREPARED_ITEMS_FOR_SAVE = 'preparedItemsForSave';
export const EMPTY_ITEMS_FOR_SAVE = 'emptyItemsForSave';

export const CURRENTCOMPANY_LOAD_INFO = 'currentCompanyLoadInfo';
export const CURRENTCOMPANY_LOGOUT = 'currentCompanyLogout';

export const REPAIRERS_FILTER_UPDATE = 'repairersFilterUpdated';
export const REPAIRERS_TABLE_SORT_BY_UPDATE = 'repairersTableSortByUpdated';
export const REPAIRERS_TABLE_SORT_DESC_UPDATE = 'repairersTableSortDescUpdated';

export const UPDATER_SET_CHECK_ACTIVITY = "updaterSetCheckActivity";
export const UPDATER_SET_CHECK_EMAIL = "updaterSetCheckEmail";
export const UPDATER_SET_CHECK_FILES = "updaterSetCheckFiles";
export const UPDATER_SET_CHECK_IMAGES = "updaterSetCheckImages";
export const UPDATER_SET_CHECK_DASHBOARD = "updaterSetCheckDashboard";
export const UPDATER_SET_LAST_TIMESTAMP = "updaterSetLastTimestamp";
