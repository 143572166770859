/*eslint-disable */

import {
    ASSESSORS_UPDATED,
    CLAIMS_FILTER_UPDATE,
    CLAIMS_TABLE_SORT_BY_UPDATE,
    CLAIMS_TABLE_SORT_DESC_UPDATE,
    REPAIRERS_FILTER_UPDATE,
    REPAIRERS_TABLE_SORT_BY_UPDATE,
} from '../../mutation-types';

export function setFilter(context, filter) {
    context.commit(REPAIRERS_FILTER_UPDATE, filter);
}

export function setTableSortBy(context, filter) {
    context.commit(CLAIMS_TABLE_SORT_BY_UPDATE, filter);
}

export function setTableSortDesc(context, filter) {
    context.commit(CLAIMS_TABLE_SORT_DESC_UPDATE, filter);
}

export function init(context, payload = 0) {
   // return getAssessorsForTableView(context, payload = 0);
}

