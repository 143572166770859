/*eslint-disable */
import Vue from 'vue';
import VueRouter from 'vue-router';

import demoRoutes from './demo.routes';
import homeRoutes from './home.routes';
import interfaceRoutes from './interface.routes';
import formsRoutes from './forms.routes';

Vue.use(VueRouter)

const routes = [
  ...demoRoutes,
  ...homeRoutes,
  ...interfaceRoutes,
  ...formsRoutes,
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
