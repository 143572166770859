/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    items: [],
    filter: '',
    tableSortBy: null,
    tableSortDesc: null,
    lastTimeCheck: {
        items: 0,
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
