/*eslint-disable */
import * as actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = {
    items: [],
    filter: '',
    assessmentsPerPage: 50,
    assessmentsAllCurrentPage: 1,
    assessmentsNewCurrentPage: 1,
    assessmentsProcessingCurrentPage: 1,
    assessmentsWRecoveriesCurrentPage: 1,
    assessmentsOnHoldCurrentPage: 1,
    assessmentsCompletedCurrentPage: 1,
    sortByForAssessmentsAll: 'number',
    sortDescForAssessmentsAll: true,
    sortByForAssessmentsNew: 'number',
    sortDescForAssessmentsNew: true,
    sortByForAssessmentsProcessing: 'number',
    sortDescForAssessmentsProcessing: true,
    sortByForAssessmentsWRecoveries: 'number',
    sortDescForAssessmentsWRecoveries: true,
    sortByForAssessmentsOnHold: 'number',
    sortDescForAssessmentsOnHold: true,
    sortByForAssessmentsCompleted: 'number',
    sortDescForAssessmentsCompleted: true,
    lastTimeCheck: {
        items: 0,
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
