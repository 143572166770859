/*eslint-disable */
import * as actions from "./actions";
import mutations from "./mutations";
import * as getters from "./getters";

const state = {
    email: "",
    fullName: "",
    groupId: 0,
    id: '',
    firstName: '',
    lastName: '',
    username: '',
    role: '',
    isActive: true,
    isVisible: false,
    workPhone: '',
    mobilePhone: '',
    businessLocation: '',
    userPhoto: '',
    activeSessions: -1,
    lastLoggedIn: ''
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
