<script>
    //import SiteFooter from './site-footer';
    import {mapGetters} from "vuex";
    export default {
        name: 'site-content',
        components: {
        //    SiteFooter
        },
        computed: {
            ...mapGetters({
                isLoggedIn: "auth/isLoggedIn"
            }),
        }
    };
</script>

<template>
    <div class="site-content">
        <!-- Content -->
        <div class="content-area">
            <div class="container-fluid">
                <router-view :key="$route.fullPath" v-if="isLoggedIn">Loading...</router-view>
            </div>
        </div>
        <!-- Footer -->

    </div>
</template>

<style>

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 5px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .3);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .3);
    }

    .V3 .col-form-label {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
    }

    .V3 .form-control {
        box-sizing: border-box;
        border: 1px solid rgba(28, 31, 57, 0.1);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border-radius: 3px;
        color: #1C1F39;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
    }

    .V3 .form-control::placeholder {
        color: rgba(28, 31, 57, 0.5);

    }

    /* WebKit, Edge */
    .V3 .form-control::-webkit-input-placeholder {
        color: rgba(28, 31, 57, 0.5);
    }

    /* Firefox 4-18 */
    .V3 .form-control:-moz-placeholder {
        color: rgba(28, 31, 57, 0.5);
    }

    /* Firefox 19+ */
    .V3 .form-control::-moz-placeholder {
        color: rgba(28, 31, 57, 0.5);
    }

    /* IE 10-11 */
    .V3 .form-control:-ms-input-placeholder {
        color: rgba(28, 31, 57, 0.5);
    }

    /* Edge */
    .V3 .form-control::-ms-input-placeholder {
        color: rgba(28, 31, 57, 0.5);
    }

</style>
