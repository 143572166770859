/*eslint-disable */
import {CURRENTUSER_LOAD_INFO, CURRENTUSER_LOGOUT, CURRENTUSER_UPDATE_USER_AVATAR} from '../../mutation-types';

export default {
    [CURRENTUSER_LOAD_INFO](state, ui) {
        state.companyId = ui.companyId;
        state.email = ui.email;
        state.lastName = ui.lastName;
        state.firstName = ui.firstName;
        state.fullName = ui.fullName;
        state.id = ui.id;
        state.username = ui.username;
        state.isActive = ui.isActive;
        state.isVisible = ui.isVisible;
        state.workPhone = ui.workPhone;
        state.mobilePhone = ui.mobilePhone;
        state.businessLocation = ui.businessLocation;
        state.userPhoto = ui.userPhoto;
        state.lastLoggedIn = ui.lastLoggedIn;
        state.activeSessions = ui.activeSessions;
        state.role = ui.role;
    },
    [CURRENTUSER_UPDATE_USER_AVATAR](state, ui) {
        state.userPhoto = ui;
    },
    [CURRENTUSER_LOGOUT](state) {
        state = {
            email: "",
            id: '',
            firstName: '',
            lastName: '',
            fullName: '',
            username: '',
            isActive: true,
            isVisible: false,
            workPhone: '',
            businessLocation: '',
            mobilePhone: '',
            userPhoto: '',
            activeSessions: -1,
            lastLoggedIn: '',
            role: '',
        };
    },

};
