/*eslint-disable */
import * as actions from "./actions";
import mutations from "./mutations";
import * as getters from "./getters";

const state = {
    showCountTasks: 5,
    showCountEmails: 5,
    showOldTasks: false,
    showOldComments: false,
    showOldEmails: false,
    reports: [],
};

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters,
};
