const HomePage = () => import(/* webpackChunkName: "home" */ "../views/home/Home");
const AuthLoginPage = () => import(/* webpackChunkName: "home" */ "../views/auth/login");
const forgotUsername = () => import(/* webpackChunkName: "home" */ "../views/auth/forgotUsername");
const forgotUsernameSupport = () => import(/* webpackChunkName: "home" */ "../views/auth/forgotUsernameSupport");
const forgotPassword = () => import(/* webpackChunkName: "home" */ "../views/auth/forgotPassword");
const forgotPasswordSupport = () => import(/* webpackChunkName: "home" */ "../views/auth/forgotPasswordSupport");
const passwordReset = () => import(/* webpackChunkName: "home" */ "../views/auth/passwordReset");


const homeRoutes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/login',
        name: 'AuthLoginPage',
        component: AuthLoginPage,
    },
    {
        path: '/auth',
        name: 'AuthLoginPageAuth',
        component: AuthLoginPage,
        children: [
            {
                path: 'forgot/username',
                name: 'ForgotUserNamePage',
                component: forgotUsername
            },
            {
                path: 'forgot/username/support',
                name: 'SupportUserNamePage',
                component: forgotUsernameSupport
            },
            {
                path: 'forgot/password',
                name: 'ForgotPasswordPage',
                component: forgotPassword
            },
            {
                path: 'forgot/username/support',
                name: 'SupportPasswordPage',
                component: forgotPasswordSupport
            },
            {
                path: 'reset-password/:code',
                name: 'ResetPasswordPage',
                component: passwordReset,
            }
        ]
    }
];
export default homeRoutes;